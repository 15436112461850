import config from 'common/config';
import apiClient from 'common/api/apiClient';

const client = {

    getAllApplication: (params) => {
        const url = `${config.api.AUTH_URL}/applicationManage/getAllApplication`;
        return apiClient.post(url,params);
    },

    addRoleApplicationConfig: (params) => {
        const url = `${config.api.AUTH_URL}/userManage/addRoleApplicationConfig`;
        return apiClient.post(url,params);
    },

    getUserAccountList: () => {
        const url = `${config.api.AUTH_URL}/userManage/getUserAccountList`;
        return apiClient.post(url);
    },

};

export default client;
