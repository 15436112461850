import React, { Component } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, Loader } from 'semantic-ui-react';
import {Input,Select,Switch } from 'antd';
import _ from 'lodash';

const Option = Select.Option;
class ApplicationManageGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columnDefs: props.applicationManageGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      autoGroupColumnDef: {
        headerName: 'Application',
        field: 'name',
        cellClass: 'keyword',
        width: 230
      },
      groupDefaultExpanded: 0,
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      selectedData: null,
      applicationManageQueryParams: {
        queryUserMode: true
      }
    };
  }

  componentDidMount(){
    this.loadAllApplicationList();
  }

  loadAllApplicationList = () =>{
    this.props.getAllApplication(this.state.applicationManageQueryParams);
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };


  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if(selectedRows){
      this.setState({
        selectedData: selectedRows[0]
      })
    }else{
      this.setState({
        selectedData: null
      })
    }
  };

  onQueryUserModeChange=()=>{
    const { applicationManageQueryParams } = this.state;
    const updateQuery = {
      ...applicationManageQueryParams,
      queryUserMode: !applicationManageQueryParams.queryUserMode
    }
    if(applicationManageQueryParams.queryUserMode){
      updateQuery['role'] = null;
    }else{
      updateQuery['englishName'] = null;
    }
    applicationManageQueryParams.queryUserMode = !applicationManageQueryParams.queryUserMode;
    this.setState({
      applicationManageQueryParams: updateQuery
    })
    this.props.getAllApplication(applicationManageQueryParams);
  }
  
  onQueryParamsChange = (event, fieldName , directFlag) => {
    const {applicationManageQueryParams} = this.state;
    const filedValue = directFlag?event:event.target.value;
    const updateData = {
      ...applicationManageQueryParams,
      [fieldName]: filedValue 
    }
    this.setState({
      applicationManageQueryParams: updateData
     });
  };

  add = () => {
    this.props.openApplicationManageControlModal();
  }

  render() {
    const {
      allApplicationList,
      isLoaded,
      className = 'grid-wrapper'
    } = this.props;
    const {applicationManageQueryParams} = this.state;
    const queryUserMode = applicationManageQueryParams.queryUserMode;
    const sortedRows = _.orderBy(allApplicationList, ['sort'], ['asc']);
    return (
      <div style={{width:'100%',height:'100%', display: 'flex' , flexDirection: 'column'}}>
        <div>
          <div style={{textAlign:'left',float:'left'}}>
            <Switch
              checked={applicationManageQueryParams.queryUserMode}
              checkedChildren="User-View"
              unCheckedChildren="Role-View"
              defaultChecked
              onChange={this.onQueryUserModeChange}
            />
          </div>
          <div
            style={{
              textAlign: 'right',
              marginBottom: '2px'
            }}
          >
             
            {
              queryUserMode?
              (<Input
                placeholder="Input EnglishName..."
                size="middle"
                style={{width:'180px'}}
                value={applicationManageQueryParams.englishName}
                onChange={e => {
                  this.onQueryParamsChange(e, 'englishName');
                }}
                onPressEnter={this.loadAllApplicationList}
              /> ):(
                <Select
                size="middle"
                style={{width:'150px',marginLeft: '5px'}}
                placeholder={'select role'}
                onChange={value => {
                  this.onQueryParamsChange(value, 'role' , true);
                }}
              > 
                  <Option value="">ALL</Option>
                  <Option value="Admin">Admin</Option>
                  <Option value="BD">BD</Option>
                  <Option value="Compliance">Compliance</Option>
                  <Option value="CustomRelationship">CustomRelationship</Option>
                  <Option value="Economist">Economist</Option>
                  <Option value="FundMngr">FundMngr</Option>
                  <Option value="IT">IT</Option>
                  <Option value="Marketing">Marketing</Option>
                  <Option value="Operation">Operation</Option>
                  <Option value="Supervisor">Supervisor</Option>
                  <Option value="Trader">Trader</Option>
              </Select>
              )
            }
            <Button
              size="mini"
              content="Reload"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px', marginLeft: '10px' }}
              onClick={this.loadAllApplicationList}
            />
            {queryUserMode?(""):
              (
                <Button
                size="mini"
                content="Add"
                color="green"
                disabled={!isLoaded}
                style={{ marginBottom: '4px', marginTop: '4px' }}
                onClick={this.add}
              />
              )
            }
          </div>
        </div>

        <div className={`ag-theme-balham-dark ${className}`} style={{ flexGrow: 1 }}>
          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={sortedRows}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              suppressAggFuncInHeader={true}
              animateRows={true}
              enableRangeSelection={true}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              groupDefaultExpanded={this.state.groupDefaultExpanded}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              context={this.state.context}
              sideBar={this.state.sideBar}
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged}
            />
          )}
        </div>
      </div>
    );
  }
}
  
export default ApplicationManageGrid;