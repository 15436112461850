import EntityMap from 'entities/EntityMap';
import {
    dateFormatter,
    dateTimeFormatter
  } from 'common/utils/valueFormatters';

const userManageGridColumns = [
    {
        field: 'officeLocation',
        headerName: 'Office Location',
        cellClass: 'non-number',
        enableRowGroup: true,
        autoHeight: true,
        rowGroup: true,
        hide: true
    } ,
    {
        field: 'desk',
        headerName: 'Desk',
        cellClass: 'non-number',
        enableRowGroup: true,
        autoHeight: true,
        rowGroup: true,
        hide: true
    } ,
    {
        field: 'seniority',
        headerName: 'Seniority',
        cellClass: 'non-number',
        hide: true
    },
    {
      field: 'domainName',
      headerName: 'Domain Name',
      cellClass: 'non-number',
      hide: true
    },
    {
      field: 'userAccountName',
      headerName: 'User Account Name',
      cellClass: 'non-number',
      hide: true
    },
    {
        field: 'englishName',
        headerName: 'English Name',
        cellClassRules: {
            'keyword': params => !( params.data && params.data.leaveDate && params.data.isActive === 1),
            'status-fail': params => params.data && params.data.leaveDate && params.data.isActive === 1,
        },
        width: 110,
        valueFormatter: params => {
            if (params.data) {
              const data = params.data;
              const name = data.leaveDate && data.isActive === 1?`${data.englishName}(已离职${data.book?'清仓中':''})`:data.englishName;
              return name;
            }
            return params.value;
        },
    },
    {
        field: 'chineseUserName',
        headerName: 'Chinese Name',
        cellClass: 'non-number',
        width: 70,
    },
    {
      field: 'title',
      headerName: 'Title',
      cellClass: 'non-number',
      enableRowGroup: true,
      width: 160,
    },
    {
      field: 'email',
      headerName: 'Email',
      cellClass: 'non-number',
      width: 120,
    },
    {
        field: 'position',
        headerName: 'Position',
        cellClass: 'non-number',
        enableRowGroup: true,
        width: 60,
    },
    {
        field: 'book',
        headerName: 'Book',
        cellClass: 'non-number',
        enableRowGroup: true,
        width: 60,
    },
    // {
    //     field: 'beauchampLoginpwd',
    //     headerName: 'Beauchamp Login Password',
    //     cellClass: 'non-number'
    // },
    {
        field: 'firm',
        headerName: 'Firm',
        cellClass: 'non-number',
        enableRowGroup: true,
        width: 70,
    },
    {
        field: 'isActive',
        headerName: 'Active',
        cellClass: 'non-number',
        enableRowGroup: true,
        valueFormatter: params => {
            const data = params.value;
            let formatValue;
            if (data === 0) {
                formatValue = 'NO';
            } else if (data === 1) {
                formatValue = 'YES';
            } else{
                formatValue = '';
            }
            return formatValue;
        },
        width: 50,
    } ,
    // {
    //     field: 'officeLocation',
    //     headerName: 'Office Location',
    //     cellClass: 'non-number'
    // }  ,
    // {
    //     field: 'pmType',
    //     headerName: 'Type',
    //     cellClass: 'non-number',
    //     enableRowGroup: true,
    // },
    {
        field: 'fund',
        headerName: 'Fund',
        cellClass: 'non-number',
        enableRowGroup: true,
        hide: true
    },
    {
        field: 'directLine',
        headerName: 'Direct Line',
        cellClass: 'non-number',
        width: 70,
    }  ,
    {
        field: 'phoneExt',
        headerName: 'Phone Ext',
        cellClass: 'non-number',
        width: 70,
    } ,
    {
        field: 'handPhone',
        headerName: 'Hand Phone',
        cellClass: 'non-number',
        width: 80,
    } ,
    {
        field: 'joinDate',
        headerName: 'Join Date',
        cellClass: 'non-number',
        valueFormatter: dateFormatter,
        width: 80,
    },
    {
        field: 'contractEndDate',
        headerName: 'Contract End Date',
        cellClass: 'non-number',
        valueFormatter: dateFormatter,
        width: 100,
    }  ,
    {
        field: 'leaveDate',
        headerName: 'Leave Date',
        cellClass: 'non-number',
        valueFormatter: dateFormatter,
        width: 80,
    } 
  ];

  export const userChangeLogGridColumn = [
    {
        field: 'opDesc',
        headerName: 'Op Desc',
        cellClass: 'non-number'
    } ,
    {
        field: 'oldValue',
        headerName: 'Before Change',
        cellClass: 'non-number'
    } ,
    {
        field: 'newValue',
        headerName: 'After Change',
        cellClass: 'non-number'
    } ,
    {
        field: 'createTime',
        headerName: 'Create Time',
        cellClass: 'non-number',
        valueFormatter: dateFormatter
    }
  ];

  export const userAccessLogGridColumn = [
    {
        field: 'op',
        headerName: 'Op',
        cellClass: 'non-number'
    } ,
    {
        field: 'createTime',
        headerName: 'Create Time',
        cellClass: 'non-number',
    }
  ];

  export const pmManageListColumn = [
    {
        field: 'manager',
        headerName: 'Manager',
        cellClass: 'non-number',
        enableRowGroup: true,
        autoHeight: true,
        rowGroup: true,
        hide: true
    } ,
    {
        field: 'fundCode',
        headerName: 'Fund',
        cellClass: 'non-number',
        enableRowGroup: true,
        autoHeight: true,
        rowGroup: true,
        hide: true
    } ,
    {
        field: 'team',
        headerName: 'Team',
        cellClass: 'non-number'
    } ,
    {
        field: 'bookCode',
        headerName: 'Book Code',
        cellClass: 'non-number'
    } ,
    {
        field: 'bookManager',
        headerName: 'Book Manager',
        cellClass: 'non-number'
    } ,
    {
        field: 'startDate',
        headerName: 'Start Date',
        cellClass: 'non-number',
        valueFormatter: dateFormatter
    } ,
    {
        field: 'endDate',
        headerName: 'End Date',
        cellClass: 'non-number',
        valueFormatter: dateFormatter
    }
  ];


  export const roleManageListColumn = [
    {
        field: 'application',
        headerName: 'Application',
        cellClass: 'non-number',
        enableRowGroup: true,
        autoHeight: true,
        rowGroup: true,
        hide: true
    },
    {
        field: 'name',
        headerName: 'Name',
        cellClass: 'non-number',
    },
    {
        field: 'description',
        headerName: 'Description',
        cellClass: 'non-number',
    }
  ];

  export const permissionManageListColumn = [
    {
        field: 'application',
        headerName: 'Application',
        cellClass: 'non-number',
        enableRowGroup: true,
        autoHeight: true,
        rowGroup: true,
        hide: true
    },
    {
        field: 'permissionName',
        headerName: 'Name',
        cellClass: 'non-number',
    },
    {
        field: 'permissionDescription',
        headerName: 'Description',
        cellClass: 'non-number',
    },
    {
        field: 'module',
        headerName: 'Module',
        cellClass: 'non-number',
    }
  ];

  export const userManageGridColumnMap = EntityMap.fromArray(
    userManageGridColumns,
    'field'
  );