const config = {
  dev: {
    api: {
      AUTH_URL: 'http://portaldev.pinpointfund.com:8330',
      RESEARCH_URL: 'http://portaldev.pinpointfund.com:8500/api',
      EXTERNAL_URL: 'http://pms.pinpointfund.com/partnership/api/v1',

      //AUTH_URL: '/auth'
    },
    env: 'dev',
    title: 'Portal-DEV'
  },
  qa: {
    api: {
      AUTH_URL: '/auth',
      RESEARCH_URL: '/rsrch',
      EXTERNAL_URL: '/pms',
    },
    env: 'qa',
    title: 'Portal-QA'
  },
  'prod': {
    api: {
      AUTH_URL: '/auth',
      RESEARCH_URL: '/rsrch',
      EXTERNAL_URL: '/pms',
    },
    env: 'prod',
    title: 'PINPOINT-PORTAL'
  }
}[process.env.REACT_APP_ENV || 'dev'];

export default config;
