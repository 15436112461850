import React, { Component } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, Loader } from 'semantic-ui-react';
import {Input,Select,DatePicker } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import OperationtMode from 'common/utils/OperationtMode';
import client from '../api/client';
import UserRolePermissionsDialog from './UserRolePermissionsDialog';
import { buildPermissionTree } from '../entity/PermissionBuild';
import {
  isAdminRole
} from 'common/utils/DomainUtils';
import UserManageDialog from './UserManageDialog'

const Option = Select.Option;
class UserManageGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columnDefs: props.userManageGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      autoGroupColumnDef: {
        cellClass: 'keyword',
        width: 120
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      selectedData: null,
      userManageQueryParams: {
        isActive: "1",
        accountType: "NORMAL",
        firm: 'PINPOINT'
      },
      userRolePermissionModal: {
        roles: [],
        roleCheckedKeys: [],
        permissions: [],
        permissionsCheckKeys: [],
        dataPermissionTree : [],
        dataPermissionCheckKeys: [],
        isOpened: false,
      },
    };
  }

  componentDidMount(){
    this.loadAllUserList();
  }

  loadAllUserList = () =>{
    this.props.loadAllUser(this.state.userManageQueryParams);
  }

  onQueryParamsChange = (event, fieldName , directFlag) => {
    const {userManageQueryParams} = this.state;
    const filedValue = directFlag?event:event.target.value;
    const updateData = {
      ...userManageQueryParams,
      [fieldName]: filedValue 
    }
    this.setState({
      userManageQueryParams: updateData
     });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  addUser = () => {
    this.props.changeUserManageControlModalInput({name: 'mode',value: OperationtMode.NEW}); 
    this.props.openUserManageControlModal()
  }

  updateUser = () => {
    if(this.state.selectedData){
      this.props.changeUserManageControlModalInput({name: 'mode',value: OperationtMode.UPDATE}); 
      this.props.changeUserManageControlModalInput({name: 'detailData',value: this.getSelectData()}); 
      this.props.openUserManageControlModal()
    }
  }

  detailUser = () => {
    if(this.state.selectedData){
      this.props.changeUserManageControlModalInput({name: 'mode',value: OperationtMode.DETAIL}); 
      this.props.changeUserManageControlModalInput({name: 'detailData',value: this.getSelectData()}); 
      this.props.getUserAccountChangeLog(this.getSelectData());
      this.props.openUserManageControlModal()
    }
  }

  resetUserPwd = () => {
    if(this.state.selectedData){
      this.props.changeUserManageControlModalInput({name: 'mode',value: OperationtMode.RESET_PWD}); 
      this.props.changeUserManageControlModalInput({name: 'detailData',value: this.getSelectData()}); 
      this.props.openUserManageControlModal()
    }
  }

  getSelectData = () =>{
    const {selectedData} = this.state;
    let joinDate = this.state.selectedData.joinDate;
    let leaveDate = this.state.selectedData.leaveDate;
    let contractEndDate = this.state.selectedData.contractEndDate;
    if(joinDate){
      joinDate = moment.utc(joinDate).utcOffset("+08:00");
    }
    if(leaveDate){
      leaveDate = moment.utc(leaveDate).utcOffset("+08:00");
    }
    if(contractEndDate){
      contractEndDate = moment.utc(contractEndDate).utcOffset("+08:00");
    }
    return {
      ...selectedData,
      joinDate,
      leaveDate,
      contractEndDate
    }
  }

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if(selectedRows){
      this.setState({
        selectedData: selectedRows[0]
      })
    }else{
      this.setState({
        selectedData: null
      })
    }
  };

  _buildPermissions = (userPermission) => {
    if(_.isEmpty(userPermission) || _.isEmpty(userPermission.data)) return;
    const data = userPermission.data;
    if(_.isEmpty(data.funcPermissions) && _.isEmpty(data.dataPermissions)) return;
    let funcPermissions = _.split(data.funcPermissions, ',');
    funcPermissions = _.isEmpty(funcPermissions)?[]:funcPermissions.map(r => parseInt(r));
    return {
      funcPermissions: funcPermissions,
      dataPermissions: _.split(data.dataPermissions, ','),
    }
  }

  _openPermissionModal = () => {
    const { selectedData, userRolePermissionModal } =this.state;
    if(!selectedData) return;
    const params = {userAccountId: selectedData.userAccountId};
    Promise.all([client.getPermissionList({}),
      client.getRoleList({}),
      client.getUserAllPermissionList(params),
      client.getAllDataAccessPermissions(),
      client.getUserDataAccessPermissions(params),
      client.getPermissions(params)])
    .then(([permissionResult, roleResult, 
      rolePermissionResult, allDataPermissionResult,
      dataPermissionResult, userPermission]) => {
      const checkedPermission = this._buildPermissions(userPermission);
      const dataPermissions = dataPermissionResult.data?
        dataPermissionResult.data:[];
      const dataPermissionCheckKeys = dataPermissions.map(r => r.permissionName);
      const dataPermissionTree = this._buildDataPermissionTreeData(allDataPermissionResult.data);
      const permissions = permissionResult.data?
        permissionResult.data:[];
      const roles = roleResult.data?
        roleResult.data:[];
      const rolePermissions = rolePermissionResult.data?
        rolePermissionResult.data:[];
      const permissionTree = this._buildPermissionTreeData(permissions);
      const roleTree = this._buildRoleTreeData(roles);
      let roleCheckedKeys = [],permissionsCheckKeys=[];
      if(!_.isEmpty(rolePermissions)){
        rolePermissions.forEach(e => {
          const { roleId, permissionId } = e;
          if(!roleCheckedKeys.includes(roleId)) roleCheckedKeys.push(roleId);
          if(!permissionsCheckKeys.includes(permissionId)) permissionsCheckKeys.push(permissionId);
        });
      }
      permissionsCheckKeys = _.isEmpty(checkedPermission)?
        permissionsCheckKeys:checkedPermission.funcPermissions;
      this.setState({
        userRolePermissionModal: {
          ...userRolePermissionModal,
          roles: roleTree,
          roleCheckedKeys,
          permissions: permissionTree,
          permissionsCheckKeys,
          dataPermissionTree,
          dataPermissionCheckKeys,
          isOpened: true,
          englishName: selectedData.englishName,
          userAccountId: selectedData.userAccountId
        },
      })
    }).catch(error => {
        console.log(error);
    });
  }

  _buildRoleTreeData = (roles) => {
    if(_.isEmpty(roles)) return [];
    const applicationData = _.groupBy(roles, 'application');
    return Object.keys(applicationData).map(r => {
      const children = applicationData[r].map(item => {
        const title = `${item.name}(${item.description})`;
        const key = item.roleId;
        return {
          title,
          key
        }
      });
      return {
        key: r,
        title: r,
        children
      }
    });
  }

  _buildPermissionTreeData = (permissions) => {
    if(_.isEmpty(permissions)) return [];
    const applicationData = permissions.map(r => ({
      parentId: r.parentPermissionId,
      key: r.permissionId,
      title: `${r.permissionName}${r.permissionDescription?'('+r.permissionDescription+')':''}`,
      type: r.type,
      application: r.application
    }));

    return buildPermissionTree(applicationData);
  }

  _buildDataPermissionTreeData = (permissions) => {
    if(_.isEmpty(permissions)) return [];
    const data = _.orderBy(permissions, ['permissionName'], ['asc'])
    const applicationData = data.map(r => ({
      parentId: r.parent,
      key: r.uniq,
      title: r.permissionName,
      application: r.application,
      disabled: r.uniq.startsWith('IMS-NON')
    }));

    return buildPermissionTree(applicationData);
  }

  _onRoleTreeCheck = (keysValue, add) => {
    const { userRolePermissionModal } =this.state;
    let expandedKeysValue = keysValue;
    if(add) {
      expandedKeysValue= [
        ...keysValue
      ]
    }
    if(_.isEmpty(expandedKeysValue)){
      this.setState({
        userRolePermissionModal: {
          ...userRolePermissionModal,
          roleCheckedKeys: expandedKeysValue,
          permissionsCheckKeys:[]
        },
      })
      return;
    }
    const roleList = expandedKeysValue.filter(r => _.isNumber(r));
    client.getRolePermissions({roleListId: roleList})
    .then(resp => {
      const permissionsCheckKeys = resp.data ? resp.data:[];
      this.setState({
        userRolePermissionModal: {
          ...userRolePermissionModal,
          roleCheckedKeys: expandedKeysValue,
          permissionsCheckKeys
        },
      })
    }).catch(error => {
      console.log(error);
    })
    
  }

  _onFundPermissionTreeCheck = (keysValue, add) => {
    const { userRolePermissionModal } =this.state;
    let expandedKeysValue = keysValue.checked;
    this.setState({
      userRolePermissionModal: {
        ...userRolePermissionModal,
        permissionsCheckKeys: expandedKeysValue,
      },
    })
  }

  _buildPermissionsCheckKeys = (permissionsCheckKeys) =>  {

  }

  _onDataPermissionTreeCheck = (keysValue, add) => {
    const { userRolePermissionModal } =this.state;
    let expandedKeysValue = keysValue;
    this.setState({
      userRolePermissionModal: {
        ...userRolePermissionModal,
        dataPermissionCheckKeys: expandedKeysValue,
      },
    })
  }

  _closeModal = () => {
    const { userRolePermissionModal } =this.state;
    this.setState({
      userRolePermissionModal: {
        ...userRolePermissionModal,
        roles: [],
        roleCheckedKeys: [],
        permissions: [],
        permissionsCheckKeys: [],
        isOpened: false,
      },
    })
  }

  _saveUserRole = () => {
    const { 
      roleCheckedKeys, 
      userAccountId,
      permissionsCheckKeys,
      dataPermissionCheckKeys, 
    } = this.state.userRolePermissionModal;
    const currentRoleCheckedKeys = roleCheckedKeys.filter(r => _.isNumber(r));
    const currentPermissionsCheckKeys = permissionsCheckKeys.filter(r => _.isNumber(r));
    const funcPermissions = !_.isEmpty(currentPermissionsCheckKeys)?
      _.join(currentPermissionsCheckKeys, ','):'';
    const dataPermissions = !_.isEmpty(dataPermissionCheckKeys)?
      _.join(dataPermissionCheckKeys, ','):'';

    Promise.all([client.saveUserRole({roleList: currentRoleCheckedKeys, 
      userAccountId}),client.savePermissions({
        userAccountId,funcPermissions,dataPermissions
      })])
    .then(([resp]) => {
        this._closeModal();
      }).catch(err => {
        console.log(err);
      })
  }

  isAdmin = () => {
    const { user } = this.props;
    return isAdminRole(user.role);
  }

  submitData = (data) => {
    this.props.submitUser(data, this.state.userManageQueryParams);
  }

  render() {
    const {
      allUsers,
      isLoaded,
      className = 'grid-wrapper'
    } = this.props;
    const {userManageQueryParams} = this.state;
    const data = _.isEmpty(allUsers)?[]:allUsers.map(r => ({
      ...r,
      englishName: r.englishName?r.englishName.toLowerCase():r.englishName
    }))
    const sortedRows = _.orderBy(data, ['englishName'], ['asc']);
    const total = _.isEmpty(sortedRows)? 0:sortedRows.length;
    return (
      <div style={{width:'100%',height:'95%', display: 'flex' , flexDirection: 'column'}}>
        <div>
          <div
            style={{
              textAlign: 'right',
              marginBottom: '2px'
            }}
          >
            <Input
              placeholder="Input EnglishName..."
              size="middle"
              style={{width:'180px'}}
              value={userManageQueryParams.englishName}
              onChange={e => {
                this.onQueryParamsChange(e, 'englishName');
              }}
              onPressEnter={this.loadAllUserList}
            /> 
            <Select
              size="middle"
              style={{width:'120px',marginLeft: '5px'}}
              defaultValue={"1"}
              onChange={value => {
                this.onQueryParamsChange(value, 'isActive' , true);
              }}
            > 
                <Option value="">ALL</Option>
                <Option value="1">YES</Option>
                <Option value="0">NO</Option>
                <Option value="2">Unemployed</Option>
            </Select>

            {/* <Select
              size="middle"
              style={{width:'120px',marginLeft: '5px'}}
              defaultValue={"NORMAL"}
              onChange={value => {
                this.onQueryParamsChange(value, 'accountType' , true);
              }}
            > 
                <Option value="">ALL</Option>
                <Option value="NORMAL">NORMAL</Option>
                <Option value="SYS">SYS</Option>
            </Select> */}

            <Select
              size="middle"
              style={{width:'120px',marginLeft: '5px'}}
              placeholder={'select firm'}
              defaultValue={"PINPOINT"}
              onChange={value => {
                this.onQueryParamsChange(value, 'firm' , true);
              }}
            > 
                <Option value="">ALL</Option>
                <Option value="PINPOINT">PINPOINT</Option>
            </Select>

            <Select
              size="middle"
              style={{width:'150px',marginLeft: '5px'}}
              placeholder={'select office location'}
              onChange={value => {
                this.onQueryParamsChange(value, 'officeLocation' , true);
              }}
            > 
                <Option value="">ALL</Option>
                <Option value="Shanghai">Shanghai</Option>
                <Option value="ShenZhen">ShenZhen</Option>
                <Option value="SanYa">SanYa</Option>
                <Option value="SanYa(QDLP)">SanYa(QDLP)</Option>
                <Option value="HongKong">HongKong</Option>
                <Option value="Japan">Japan</Option>
                <Option value="India">India</Option>
                <Option value="Singapore">Singapore</Option>
                <Option value="Unkown">Unkown</Option>
            </Select>
            
            <DatePicker 
              allowClear={true}
              placeholder={"join date from"}
              onChange={(date, dateString) => {
              this.onQueryParamsChange(dateString, 'joinDate', true);
            }} />

            <DatePicker 
              allowClear={true}
              placeholder={"leave date from"}
              onChange={(date, dateString) => {
              this.onQueryParamsChange(dateString, 'leaveDate', true);
            }} />

            <Button
              size="mini"
              content="Reload"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px', marginLeft: '10px' }}
              onClick={this.loadAllUserList}
            />
                {this.isAdmin() && <Button
                  size="mini"
                  content="Add"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px' }}
                  onClick={this.addUser}
                  />}

                <Button
                  size="mini"
                  content="Update"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px' }}
                  onClick={this.updateUser}
                />

                {this.isAdmin() && <Button
                  size="mini"
                  content="Reset PWD"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px'}}
                  onClick={this.resetUserPwd}
                />}

                {this.isAdmin() && <Button
                  size="mini"
                  content="Permissions"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px'}}
                  onClick={this._openPermissionModal}
                />}

                <Button
                  size="mini"
                  content="Detail"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px'}}
                  onClick={this.detailUser}
                />
          </div>
        </div>
          {!isLoaded ? (
              <Loader active inverted content="Loading..." />
            ) : (
              <div className={`ag-theme-balham-dark ${className}`} style={{ flexGrow: 1 }}>
                  <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={sortedRows}
                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                    suppressAggFuncInHeader={true}
                    animateRows={true}
                    enableRangeSelection={true}
                    rowGroupPanelShow={this.state.rowGroupPanelShow}
                    groupDefaultExpanded={this.state.groupDefaultExpanded}
                    pivotPanelShow={this.state.pivotPanelShow}
                    rowSelection="single"
                    context={this.state.context}
                    sideBar={this.state.sideBar}
                    onGridReady={this.onGridReady}
                    onSelectionChanged={this.onSelectionChanged}
                  />
               </div>
            )}
            <div style={{width: '100%',
                textAlign: 'right', 
                fontSize: '18px',
                height: '30px',
                marginTop: '5px'}}>
              <span style={{color: '#0099CC', marginRight: '5px'}}>Total:</span> 
              <span style={{color: '#969696', marginRight: '15px' }}>{total}</span>
            </div>

            <UserRolePermissionsDialog 
              {...this.props}
              modalData={this.state.userRolePermissionModal}
              closeModal={this._closeModal}
              onPermissionTreeCheck={this._onPermissionTreeCheck}
              onRoleTreeCheck={this._onRoleTreeCheck}
              onFundPermissionTreeCheck={this._onFundPermissionTreeCheck}
              onDataPermissionTreeCheck={this._onDataPermissionTreeCheck}
              onSubmit={this._saveUserRole}
            />

          <UserManageDialog
            {...this.props}
            submitData={this.submitData}
          />
      </div>
    );
  }
}
  
export default UserManageGrid;